import React from "react";

function Message(isVisible) {
  return (
    <>
      <span className={isVisible}>
        <pre>{`
От 1 юли 2024 г. започва подаването на творби,
които да участват в Националния конкурс за поезия
„Академик Николай Лилиев“. Целта му е да подпомага
и популяризира поетичното творчество на автори над
18 години. Съревнованието на майсторите на перото се
организира от Община Стара Загора, Дружество на
писателите – Стара Загора, Фондация „Космос – Димитър Брацов”,
Регионална библиотека „Захарий Княжески”. То се провежда всяка
година с подкрепата на Народно читалище „Николай Лилиев“ – 2005”
– Стара Загора и Народно читалище „Даскал Петър Иванов – 1988”
– Стара Загора. В състава на конкурсното жури влизат утвърдени
имена от съвременния литературен живот. Медийни партньори на
конкурса са списание „Птици в нощта” и вестник „Литературен глас”.

Условия за участие:
Конкурсът е анонимен, на свободна тема. Възраст на участниците: автори,
навършили 18 години. Участие могат да вземат автори с творби,
непубликувани под каквато и да е форма към крайната дата на кандидатстване.
Всеки автор изпраща имейл, съдържащ до 3 (три)
поетични произведения на електронен адрес:
n-liliev@abv.bg,
в срок до 11 септември 2024 г.
Писмата (имейлите) трябва да съдържат заглавие, относно „За конкурса” и
два прикачени файла: единият – с трите стихотворения заедно, другият – с
личните данни на участника (три имена, дата, месец и година на раждане,
пощенски адрес, телефон). Предоставените лични данни служат само за целите
на конкурса, за идентифициране и награждаване на победителите.
В конкурса НЕ МОГАТ да участват: членове на конкурсното жури,
организатори на конкурса, носители на първа награда от предишните издания.
Отчитането на резултатите е на 2 октомври. Церемонията по награждаването е
част от Есенни литературни дни - Стара Загора 2024 г. Изпращайки творбите си,
авторите се съгласяват имената и творбите им да бъдат публикувани в
социалните мрежи, печатни и електронни медии в публикации, свързани с конкурса.

Наградите са парични.
Първа награда – Награда на Кмета на Община Стара Загора.
Втора награда – Награда на Фондация „Космос – Димитър Брацов”.
Трета награда – Народно читалище „Николай Лилиев – 2005”.
По преценка на журито се връчват и поощрения от Дружество на
писателите – Стара Загора и Народно читалище „Даскал Петър Иванов – 1988”.
Всички лауреати от конкурса получават Почетна грамота.

                `}</pre>
      </span>
    </>
  );
}

export default Message;
